import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@/cineamo-frontend-lib/components/ui/buttons/Button';

import { showToastNew } from '@/cineamo-frontend-lib/helper/toast-helper';
import { getCampaignForQueryValue } from '@/src/helper/campaign/campaign-helper';
import { UseCampaignOptions, UseCampaignReturn } from '@/src/helper/campaign/campaign.types';

import useRouterQueryKeyValue from '@/cineamo-frontend-lib/hooks/useRouterQueryKeyValue';

import { useCinemaStore } from '@/store/cinema/cinemaStore';

export function useCampaign(options: UseCampaignOptions): UseCampaignReturn {
    // const { t } = useTranslation();
    const { locale } = useRouter();
    const { selectedCinemaIds, cinemasNearbyCount } = useCinemaStore();

    const { queryValue: queryValueCampaign } = useRouterQueryKeyValue('utm_campaign');
    const { queryValue: queryValueShowAddCinema } = useRouterQueryKeyValue('showAddCinema');
    const [isMounted, setIsMounted] = useState(false);
    // const [removeCurtains, setRemoveCurtains] = useState(false);
    const [showAddCinemaModalOpen, setShowAddCinemaModalOpen] = useState(false);
    // const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
    const campaign = getCampaignForQueryValue(queryValueCampaign);
    // const customOverlayRef = useRef(typeof document !== 'undefined' && document.getElementById('mobile-sidemenu'));
    // const isMobile = useIsMobile();

    const welcomeTitle = campaign?.translations?.[locale]?.welcomeTitle;
    const welcomeDescription = campaign?.translations?.[locale]?.welcomeDescription;
    const welcomeButton = campaign?.translations?.[locale]?.welcomeButton;

    function onLetsGoClick() {
        // setShowWelcomeMessage(false);
        if (queryValueShowAddCinema) {
            if (selectedCinemaIds.length > 0 || cinemasNearbyCount > 0) {
                return;
            }
            setShowAddCinemaModalOpen(true);
        }
        // setTimeout(() => {
        //     setRemoveCurtains(true);
        // }, 1000);
    }

    const buttonLetsGo = (
        <Button
            className="h-48 text-body-14 flex-none"
            style={{
                backgroundColor: campaign?.colors?.primaryColor,
                color: campaign?.colors?.onPrimaryColor
            }}
            onClick={onLetsGoClick}>
            {welcomeButton}
        </Button>
    );

    const welcomeModal: JSX.Element = null;
    // const welcomeModal: JSX.Element = options?.showWelcomeMessage && (
    //     <Modal
    //         className="!min-h-[400px] max-h-full sm:!max-h-[50%] !h-full"
    //         zIndex={99999}
    //         styleDialogPanel={{
    //             backgroundColor: campaign?.colors?.backgroundColor,
    //             color: campaign?.colors?.onBackgroundColor
    //         }}
    //         backgroundColor={campaign?.colors?.backgroundColor}
    //         showBackground={false}
    //         modalIsOpen={showWelcomeMessage}
    //         setModalIsOpen={setShowWelcomeMessage}
    //         title={welcomeTitle}
    //         showCloseButton={false}
    //         onClose={() => onLetsGoClick()}
    //         t={t}>
    //         <div className="relative h-0 flex flex-col flex-auto justify-between space-y-16">
    //             <span className="whitespace-pre-wrap overflow-y-scroll">{welcomeDescription}</span>
    //             {buttonLetsGo}
    //         </div>
    //     </Modal>
    // );

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (!isMounted) {
            return;
        }
        if (!campaign) {
            return;
        }
        if (options?.showWelcomeMessage) {
            // if (!isMobile) {
            showToastNew({
                toastType: toast,
                messageTitle: welcomeTitle,
                messageBody: welcomeDescription,
                backgroundColor: campaign.colors?.backgroundColor,
                textColor: campaign.colors?.onBackgroundColor,
                messageIcon: campaign.assets?.welcomeIcon,
                autoClose: false,
                messageButton: buttonLetsGo
            });
            // }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted]);
    // const customOverlay =
    //     campaign && isMounted && customOverlayRef.current && !removeCurtains
    //         ? ReactDOM.createPortal(
    //               <div className={classnames('fixed w-full h-full left-0 top-0', 'z-[9000] backdrop-blur-sm')}>
    //                   <div
    //                       className={classnames(
    //                           'absolute w-1/2 h-full',
    //                           'duration-[1.5s]',
    //                           showAddCinemaModalOpen ? 'opacity-0' : 'left-0 opacity-80'
    //                       )}
    //                       style={{
    //                           backgroundColor: campaign.colors?.backgroundColor || '#000000'
    //                       }}
    //                   />
    //                   <div
    //                       className={classnames(
    //                           'absolute w-1/2 h-full right-0',
    //                           'duration-[1.5s]',
    //                           showAddCinemaModalOpen ? 'opacity-0' : 'opacity-80'
    //                       )}
    //                       style={{
    //                           backgroundColor: campaign.colors?.backgroundColor || '#000000'
    //                       }}
    //                   />
    //               </div>,
    //               customOverlayRef.current,
    //               'custom-overlay'
    //           )
    //         : undefined;
    return {
        campaign: campaign,
        showAddCinema: campaign ? showAddCinemaModalOpen : false,
        welcomeModal: campaign ? welcomeModal : null
        // customOverlay: campaign ? customOverlay : null
    };
}
