import classnames from 'classnames';
import Image from 'next/future/image';
import React, { useCallback } from 'react';

import { MovieDto } from '@/cineamo-frontend-lib/models/movie/MovieDto.types';

import { TMDB_IMAGE_RESOLUTION } from '@/cineamo-frontend-lib/constants/tmdb-constants';

import {
    getMovieBackdropBlurDataImage,
    getMovieBackdropImage,
    getMovieTitle
} from '@/cineamo-frontend-lib/helper/movie-helper';

type SliderImagesProps = {
    showSelectedMovie?: boolean;
    selectedMovie?: MovieDto;
    selectedMovie2?: MovieDto;
    locale: string;
};

function SliderImages(props: SliderImagesProps): JSX.Element {
    const { showSelectedMovie, selectedMovie, selectedMovie2, locale } = props;
    const movieImage = useCallback(
        (movie: MovieDto) => ({
            imageURL: getMovieBackdropImage(movie, false, true, false, {
                resolution: TMDB_IMAGE_RESOLUTION.ORIGINAL
            }),
            blurDataURL: getMovieBackdropBlurDataImage(movie, false, false)
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedMovie, selectedMovie2]
    );
    return (
        <>
            <Image
                className={classnames(
                    'absolute left-0 w-full h-full object-cover',
                    'transition-all duration-500',
                    showSelectedMovie ? 'opacity-100' : 'opacity-0',
                    selectedMovie ? 'object-top' : 'object-center'
                )}
                src={movieImage(selectedMovie).imageURL}
                blurDataURL={movieImage(selectedMovie).blurDataURL}
                width={20000}
                height={20000}
                priority
                alt={`${(selectedMovie && getMovieTitle(selectedMovie, locale)) || ''} Backdrop`}
            />
            <Image
                className={classnames(
                    'absolute left-0 w-full h-full object-cover',
                    'transition-all duration-500',
                    !showSelectedMovie ? 'opacity-100' : 'opacity-0',
                    selectedMovie2 ? 'object-top' : 'object-center'
                )}
                src={movieImage(selectedMovie2).imageURL}
                blurDataURL={movieImage(selectedMovie2).blurDataURL}
                width={20000}
                height={20000}
                priority
                alt={`${(selectedMovie2 && getMovieTitle(selectedMovie2, locale)) || ''} Backdrop`}
            />
        </>
    );
}

export default SliderImages;
