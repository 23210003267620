import { MutableRefObject, useEffect, useRef, useState } from 'react';

type HorizontalSwipeRecognizerOptions = {
    onLeftSwipe?: () => void;
    onRightSwipe?: () => void;
};

type HorizontalSwipeRecognizerReturn = {
    ref: MutableRefObject<HTMLDivElement>;
};

function useHorizontalSwipeRecognizer(options: HorizontalSwipeRecognizerOptions): HorizontalSwipeRecognizerReturn {
    const ref = useRef<HTMLDivElement>();
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50;

    const onTouchStart = (e) => {
        setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    };

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isLeftSwipe) {
            options?.onLeftSwipe && options.onLeftSwipe();
        } else if (isRightSwipe) {
            options?.onRightSwipe && options.onRightSwipe();
        }
        // add your conditional logic here
    };

    useEffect(() => {
        if (ref?.current) {
            ref.current.ontouchstart = onTouchStart;
            ref.current.ontouchmove = onTouchMove;
            ref.current.ontouchend = onTouchEnd;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    return {
        ref: ref
    };
}

export default useHorizontalSwipeRecognizer;
