import { ContentDto } from '../../models/content/ContentDto';
import { ContentInputDto } from '../../models/content/ContentInputDto';

import { CountryCode } from '../../types/CountryCode';

import { UrlQueryParameter, buildUrlQuery } from '../../helper/url-query-helper';

import { ContentListResponse } from '../ApiListResponse.types';
import { ApiResponse } from '../ApiResponse.types';
import apiClient from '../cineamoApiClient';

// <------------------------ Contents ------------------------>

export type GetContentsQuery = {
    cineamoMovieId?: string;
    showingStartDateTime?: string;
    showingEndDateTime?: string;
    metricStartDatetime?: string;
    metricEndDatetime?: string;
    cinemaId?: number | string;
    showingDate?: string;
    hasMarketingAssistantBooked?: boolean;
    isMovie?: boolean;
    isShowingNow?: boolean;
    cinemaCountryCode?: CountryCode;
} & UrlQueryParameter;

/**
 * This endpoint returns a list of content.
 *
 * @example
 * // Get a list of content:
 * getContents({...query});
 *
 * @param query
 * @returns {ApiResponse<ContentListResponse>}
 */

export async function getContents(query: GetContentsQuery): Promise<ApiResponse<ContentListResponse>> {
    return apiClient
        .get(`/contents${buildUrlQuery(query)}`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Content ------------------------>

/**
 * This endpoint returns a content.
 *
 * @example
 * // Get a single content:
 * getContentById(1);
 *
 * @param id
 * @returns {ApiResponse<ContentDto>}
 */

export async function getContentById(id: string | number): Promise<ApiResponse<ContentDto>> {
    return apiClient
        .get<ApiResponse<ContentListResponse>>(`/contents/${id}`)
        .then((response) => response.data)
        .catch((error) => {
            console.error(error);
            return null;
        });
}

export async function updateContent(content: ContentInputDto): Promise<ApiResponse<ContentDto>> {
    return apiClient
        .patch<ApiResponse<ContentDto>>(`/contents/${content.id}`, content)
        .then((response) => response.data)
        .catch((error) => error);
}
