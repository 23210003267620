import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import React from 'react';

import Modal, { ModalProps } from '@/cineamo-frontend-lib/components/ui/modal/Modal';

import { loadAsset } from '@/cineamo-frontend-lib/helper/asset-helper';

const LocationImage = loadAsset('/images/illustrations/im-location.png');

export default function NoLocationModal(props: ModalProps): JSX.Element {
    const { modalIsOpen, setModalIsOpen } = props;

    const { t } = useTranslation('cinema');

    return (
        <Modal
            {...props}
            title={t('turn-location-on')}
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
            onClose={null}>
            <div className="w-250 relative mx-auto mb-24">
                <Image
                    layout="responsive"
                    width={100}
                    height={100}
                    src={LocationImage}
                    alt="No Location"
                />
            </div>
            <p className="">{t('improve-search-results')}</p>
        </Modal>
    );
}
