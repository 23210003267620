import { useTranslation } from 'next-i18next';
import React from 'react';

import CenteredLogosWithTitle, { CenteredLogosWithTitleLogo } from '@/components/ui/text/CenteredLogosWithTitle';

import { loadAsset } from '@/cineamo-frontend-lib/helper/asset-helper';

const bundesministeriumWirtschaftEnergie = loadAsset(
    '/images/companies/partners/im-bundesministerium-wirtschaft-energie.png'
);
const germanAccelerator = loadAsset('/images/companies/partners/im-germanAccelerator.png');
const hdfLogo = loadAsset('/images/companies/partners/im-hdfLogoWhite.svg');
const moviepilotLogo = loadAsset('/images/companies/partners/im-moviePilotLogoWhite.svg');
const schuleFuerMorgen = loadAsset('/images/companies/partners/im-schuleFuerMorgen.png');
const studiocanalLogo = loadAsset('/images/companies/partners/im-studiocanal.png');

export default function PartnerLogosCenteredWithTitle(): JSX.Element {
    const { t } = useTranslation('common');

    const partner: CenteredLogosWithTitleLogo[] = [
        { id: 'l1', url: studiocanalLogo, link: 'https://www.studiocanal.de/' },
        { id: 'l2', url: bundesministeriumWirtschaftEnergie, link: 'https://www.bmwi.de/', logoClass: 'max-h-160' },
        { id: 'l3', url: hdfLogo, link: 'https://www.hdf-kino.de/', logoClass: 'w-108' },
        { id: 'l4', url: moviepilotLogo, link: 'https://www.moviepilot.de/' },
        { id: 'l5', url: schuleFuerMorgen, link: 'https://schule-fuer-morgen.de/', logoClass: 'max-h-160' },
        { id: 'l5', url: germanAccelerator, link: 'https://www.germanaccelerator.com/', logoClass: 'w-240' }
    ];

    return (
        <CenteredLogosWithTitle
            title={t('common:our-partners')}
            logos={partner}
        />
    );
}
