import React from 'react';
import {CustomSVGProps} from '@/cineamo-frontend-lib/types/CustomSVGProps.types';

export function IconKinoFuehlstDu(props: CustomSVGProps): JSX.Element {
    return (
        <svg
            {...props}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 0H22C27.5228 0 32 4.47715 32 10V32H10C4.47715 32 0 27.5228 0 22V0Z"
                fill="black"
            />
            <path
                d="M29 10L29 29L10 29C6.13401 29 3 25.866 3 22L3 3L22 3C25.866 3 29 6.13401 29 10Z"
                stroke="#FFF184"
                strokeWidth="2"
            />
            <path
                d="M13.656 19.328L11.784 16.76L17.568 8H23.616L13.656 19.328ZM9 24.008V8H13.824V24.008H9ZM17.472 13.712L24.072 24.008H18.144L13.728 16.76L17.472 13.712Z"
                fill="#FFF184"
            />
        </svg>
    );
}
