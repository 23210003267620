import { useTranslation } from 'next-i18next';
import Image from 'next/future/image';

import Button, { ButtonStyle } from '@/cineamo-frontend-lib/components/ui/buttons/Button';
import TwoColumnsOnLg from '@/cineamo-frontend-lib/components/ui/layout-components/TwoColumnsOnLg';
import MobileOrDefaultRenderer from '@/components/layouts/MobileOrDefaultRenderer';
import LinkWithStyle from '@/components/ui/link/LinkWithStyle';

import { loadAsset } from '@/cineamo-frontend-lib/helper/asset-helper';
import { StyleFadeDirection, styleFaded } from '@/cineamo-frontend-lib/helper/styleHelper';

const cinemaScreenImage = loadAsset('/images/placeholders/im-cinemaScreen.png');

function RentYourCinemaCard() {
    const { t } = useTranslation();

    return (
        <div className="bg-white bg-opacity-10 rounded-8 overflow-hidden">
            <MobileOrDefaultRenderer
                mobileContent={
                    <div className="relative flex flex-col w-full h-260 justify-center p-16">
                        <Image
                            className="absolute left-0 bottom-0 min-w-full min-h-400 object-cover transform -scale-x-100 scale-y-100 object-left-bottom"
                            src={cinemaScreenImage}
                            width={1000}
                            height={1000}
                            alt="Cinema"
                        />
                        <div className="relative flex flex-col space-y-16">
                            <span className="text-center text-headline-18">
                                {t('landing-page:nothing-for-you-book-your-own-cinema-screen')}
                            </span>
                            <LinkWithStyle
                                className="self-center"
                                href={'/program/request'}>
                                <Button>{t('landing-page:book-cinema-screen')}</Button>
                            </LinkWithStyle>
                        </div>
                    </div>
                }
                defaultContent={
                    <TwoColumnsOnLg
                        left={
                            <Image
                                className="w-auto h-full object-cover"
                                style={styleFaded(StyleFadeDirection.Right, 20)}
                                src={cinemaScreenImage}
                                width={1000}
                                height={1000}
                                alt="Cinema"
                            />
                        }
                        right={
                            <div className="my-auto py-48 px-24">
                                <div className="text-headline-20">{t('landing-page:book-your-own-cinema-screen')}</div>
                                <div className="text-body-14 mt-12">
                                    {t('landing-page:not-convinced-by-cinema-program')}
                                </div>
                                <div className="flex space-x-16 mt-16">
                                    <LinkWithStyle href={'/cinema-visitor/how-it-works'}>
                                        <Button style={ButtonStyle.outlined}>{t('landing-page:learn-more')}</Button>
                                    </LinkWithStyle>
                                    <LinkWithStyle href={'/program/request'}>
                                        <Button>{t('landing-page:book-cinema-screen')}</Button>
                                    </LinkWithStyle>
                                </div>
                            </div>
                        }
                    />
                }
            />
        </div>
    );
}

export default RentYourCinemaCard;
