import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/future/image';
import React, { useEffect, useRef, useState } from 'react';

import Button from '@/cineamo-frontend-lib/components/ui/buttons/Button';
import SearchBar from '@/cineamo-frontend-lib/components/ui/input/SearchBar';
import CenterLayout from '@/cineamo-frontend-lib/components/website/CenterLayout';
import CinemasSlider from '@/components/pages/landingpage/CinemasSlider';
import NoLocationModal from '@/components/ui/modal/NoLocationModal';
import TwoColumnsOnLg from '@/components/ui/website/TwoColumnsOnLg';

import { CinemaDto } from '@/cineamo-frontend-lib/models/cinema/CinemaDto.types';

import { CinemaListResponse } from '@/cineamo-frontend-lib/api/ApiListResponse.types';
import { ApiResponse } from '@/cineamo-frontend-lib/api/ApiResponse.types';
import { getCinemas } from '@/cineamo-frontend-lib/api/cinema/cinema-api';

import { loadAsset } from '@/cineamo-frontend-lib/helper/asset-helper';
import { checkUserLocationGranted, getLocation } from '@/src/helper/LocationHelper';
import { useIsMobile } from '@/src/helper/device/screen-type';

import { usePagination } from '@/cineamo-frontend-lib/hooks/usePagination';

import { useLoadingAnimation } from '@/store/loading-animation-context';
import { useLocationStore } from '@/store/location/locationStore';

import { IconPosition24 } from '@/cineamo-frontend-lib/icons/menu/ic-position';

const ImageLocation = loadAsset('/images/illustrations/im-location.png');

type ChooseCinemaSectionProps = {
    cinemasProp: ApiResponse<CinemaListResponse>;
    locale?: string;
};

function ChooseCinemaSection(props: ChooseCinemaSectionProps) {
    const { locale } = props;

    const { t } = useTranslation('landing-page');
    const isMobile = useIsMobile();
    const searchInputRef = useRef(null);

    const topCities: string[] = [
        'Villingen-Schwenningen',
        'Berlin',
        'Kassel',
        'Köln',
        'München',
        'Stuttgart',
        'Würzburg'
    ];

    const { showLoadingAnimation, hideLoadingAnimation } = useLoadingAnimation();
    const { longitude, latitude, setLocation, setLocationLevel, locationLevel } = useLocationStore();

    const [searchQuery, setSearchQuery] = useState<string>();
    const [selectedCity, setSelectedCity] = useState<string>();
    const [selectedCinema, setSelectedCinema] = useState<CinemaDto>(null);
    // const [userLocation, setUserLocation] = useState<LocationInterface | false | null>(null);
    const [locatingFirstTime, setLocatingFirstTime] = useState(true);
    const [noLocationModalIsOpen, setNoLocationModalIsOpen] = useState(true);

    const {
        ref: cinemaListResponseRef,
        data: cinemaListResponse,
        isFetching: isFetchingCinemaList,
        // isFetchingNextPage,
        hasNextPage: cinemaListResponseHasNextPage
        // hasPreviousPage,
    } = usePagination<ApiResponse<CinemaListResponse>>(
        ['cinemas', locationLevel, searchQuery],
        (pageParam) =>
            getCinemas({
                page: pageParam,
                longitude: locationLevel ? longitude : null,
                latitude: locationLevel ? latitude : null,
                query: searchQuery
            })
        //    TODO: Check why initial data breaks the search
        //    Looks like the changing dependencies will then not trigger a refetch/reload of the data anymore.
        //     , {
        //     initialData: {
        //         pages: [
        //             cinemasProp
        //         ],
        //         pageParams: [cinemasProp?._page]
        //     }
        // }
    );

    useEffect(() => {
        const firstCinemaOrNull = cinemaListResponse?.pages?.[0]?._embedded?.cinemas?.[0];
        if (!selectedCinema && firstCinemaOrNull) {
            setSelectedCinema(firstCinemaOrNull);
        }
        hideLoadingAnimation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cinemaListResponse]);

    function getAndSetUserLocation() {
        getLocation(
            (position) => {
                setLocation(position.longitude, position.latitude);
                if (locatingFirstTime === true) {
                    showLoadingAnimation();
                }
                setLocatingFirstTime(false);
            },
            () => {
                setNoLocationModalIsOpen(!locationLevel || locationLevel === 'ip');
                // setLocation(false);
            }
        );

        checkUserLocationGranted().then((granted) => {
            if (!granted) {
                setLocationLevel('ip');
            }
        });
    }

    function handleSearchInputChange(value: string) {
        const searchQuery = value?.toLowerCase() || '';
        if (value?.length > 0) {
            setSearchQuery(searchQuery);
            setSelectedCity(undefined);
        }
    }

    function cityClickHandler(city: string) {
        // TODO: Handle city click
        const selectCity = selectedCity === city ? undefined : city;
        setSelectedCity(selectCity);
        setSearchQuery(selectCity);
        searchInputRef.current.value = null;
    }

    return (
        <>
            {locationLevel === undefined && (
                <NoLocationModal
                    modalIsOpen={noLocationModalIsOpen}
                    setModalIsOpen={setNoLocationModalIsOpen}
                    t={t}
                />
            )}

            <CenterLayout>
                <div className="flex flex-col space-y-32">
                    <TwoColumnsOnLg
                        className="w-full"
                        left={
                            <div className="flex flex-col space-y-32">
                                <div className="flex flex-col space-y-12">
                                    <span className="text-overline-16 text-cyan">{t('landing-page:your-cinema')}</span>
                                    <span className="text-headline-24">
                                        {t('landing-page:connect-to-your-favorite-cinema')}
                                    </span>
                                </div>
                                <div className="w-full h-44 flex items-center space-x-8">
                                    <Button
                                        className="h-full !px-0 md:!px-12 w-48 md:w-auto"
                                        labelClassName="hidden md:block"
                                        leadingIcon={<IconPosition24 />}
                                        onClick={getAndSetUserLocation}>
                                        {t('cinema:get-location')}
                                    </Button>
                                    <SearchBar
                                        className="w-full"
                                        placeholder={t('common:search')}
                                        onChange={handleSearchInputChange}
                                        searchInputRef={searchInputRef}
                                    />
                                </div>
                                <div className="flex flex-row space-x-8 text-white-50">
                                    <span
                                        className={classnames('text-body-bold-14 flex-none', {
                                            'py-8 my-2': isMobile
                                        })}>
                                        {t('landing-page:top-cities')}:
                                    </span>
                                    <div className="flex flex-wrap space-x-8">
                                        {topCities.map((city, index) => (
                                            <span
                                                key={`city#${index}`}
                                                className={classnames(
                                                    'clickable-element',
                                                    city === selectedCity
                                                        ? 'text-cyan text-body-bold-14'
                                                        : 'text-body-14',
                                                    { 'px-16 py-8 mx-4 my-2': isMobile }
                                                )}
                                                onClick={() => cityClickHandler(city)}>
                                                {city}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        }
                        right={
                            !isMobile && (
                                <Image
                                    className="w-auto max-w-full h-240 self-center"
                                    src={ImageLocation}
                                    width={960}
                                    height={960}
                                    alt=""
                                />
                            )
                        }
                        leftExtraClass="lg:!w-2/3"
                        rightExtraClass="lg:!w-1/3 items-end"
                    />

                    <CinemasSlider
                        locale={locale}
                        ref={cinemaListResponseRef}
                        data={cinemaListResponse}
                        isFetching={isFetchingCinemaList}
                        hasNextPage={cinemaListResponseHasNextPage}
                    />
                </div>
            </CenterLayout>
        </>
    );
}

export default ChooseCinemaSection;
