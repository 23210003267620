import { NextSeo } from 'next-seo';
import { OpenGraphMedia, OpenGraphVideo } from 'next-seo/lib/types';
import { translateUrl } from 'next-translate-routes';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { DOMAIN_URL } from '@/src/constants/httpConstants';

import { isMobile } from '@/src/helper/device/screen-type';
import { TrimBy, trimTextToLength } from '@/src/helper/stringHelper';

export type CineamoSeoProps = {
    title: string;
    description: string;
    image: string;
    imageAlt: string;
    video?: { info: OpenGraphVideo; video: OpenGraphMedia[] };
    locale: string;
    cinemaSlug?: string;
    ogTitle?: string;
    ogDescription?: string;
    ogImages?: string;
    ogType?: string;
    canonical?: string;
};

function CineamoSeo(props: CineamoSeoProps): JSX.Element {
    const router = useRouter();

    const { cinemaSlug } = props;

    const url = router?.asPath
        ? process.env.NEXT_PUBLIC_ENV_LEGACY_HOST_NAME + router.asPath.replace('/', '')
        : process.env.NEXT_PUBLIC_ENV_LEGACY_HOST_NAME;

    function getCanonicalUrl(): string {
        try {
            let currentURL = router.pathname;
            Object.keys(router.query).forEach((key) => {
                currentURL = currentURL.replace(`[${key}]`, router.query[key].toString());
            });
            return DOMAIN_URL + currentURL.replace(/^\//g, '');
        } catch (e) {
            return props.canonical;
        }
    }

    function removeStartingSlash(string) {
        return string.startsWith('/') ? string.replace('/', '') : string;
    }

    // TODO: Should be removed from here!!!
    async function replaceIdWithSlug() {
        if (cinemaSlug && router?.query?.cinemaId !== cinemaSlug) {
            await router.replace(
                {
                    pathname: router.pathname,
                    query: {
                        ...router.query,
                        cinemaId: cinemaSlug
                    }
                },
                null,
                { shallow: true }
            );
        }
    }

    const languageAlternates = [];

    // currentTranslatedPathOption1(): Good one which works well. But sometimes if we use [[...slug]]-Dynamic-Slugs or how they're called
    // it will not work ... If so, we use option 2: currentTranslatedPathOption2()
    const currentTranslatedPathOption1 = (locale: string) => {
        const url: { pathname: string; query: { [p: string]: string | string[] } } = {
            pathname: router.pathname,
            query: { ...router.query }
        };
        return removeStartingSlash(translateUrl(url, locale)?.pathname || translateUrl(router.asPath, locale));
    };

    // Fallback option to get the translatedPath
    const currentTranslatedPathOption2 = (locale: string) => {
        const pathToTranslate = buildPath(router.pathname, router.query);
        return removeStartingSlash(translateUrl(pathToTranslate, locale))?.toString();
    };

    useEffect(() => {
        replaceIdWithSlug().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    try {
        // Option 1 which sometimes does not work (don't know why, yet)
        initializeLanguageAlternates(currentTranslatedPathOption1);
    } catch {
        try {
            // Option 2 which works good but not perfect (as a fallback to Option 1)
            initializeLanguageAlternates(currentTranslatedPathOption2);
        } catch {
            console.error('Cannot initialize language alternates!');
        }
    }

    function buildPath(path, params) {
        path = path.replace(/\[([^\]]+)]/g, (m, c0) => {
            let key = c0;
            if (key.startsWith('...')) {
                key = key.replace('...', '');
            }
            let value = key in params ? params[key] : '';
            if (value instanceof Array) {
                value = value.join('/');
            }
            return value;
        });
        return path;
    }

    function initializeLanguageAlternates(fct: (locale: string) => string) {
        // Add translated paths for all available locales except defaultLocale
        router.locales?.forEach((locale) => {
            if (router.defaultLocale && locale !== router.defaultLocale) {
                languageAlternates.push({
                    hrefLang: locale,
                    href: DOMAIN_URL + fct(locale)
                });
            }
        });

        // Add defaultLocale translated path
        if (router?.defaultLocale) {
            languageAlternates.push({
                hrefLang: router.defaultLocale,
                href: DOMAIN_URL + fct(router.defaultLocale)
            });
        }
    }

    return (
        <NextSeo
            title={props.title}
            description={trimTextToLength(
                trimTextToLength(props.description, 82, '', TrimBy.Words),
                isMobile ? 125 : 160,
                '...',
                TrimBy.All
            )}
            languageAlternates={languageAlternates}
            additionalMetaTags={[{ name: 'Googlebot', content: 'noarchive' }]}
            canonical={getCanonicalUrl()}
            openGraph={{
                type: props.ogType || 'website',
                url: url,
                title: props.ogTitle || props.title,
                description: props.ogDescription || props.description,
                locale: props.locale,
                images: props.image
                    ? [
                          {
                              url: props.image.startsWith('/')
                                  ? DOMAIN_URL + removeStartingSlash(props.image)
                                  : props.image,
                              width: 800,
                              height: 800,
                              alt: props.imageAlt
                          }
                      ]
                    : [],
                video: props.video?.info,
                videos: props.video?.video
            }}
        />
    );
}

export default CineamoSeo;
