import classnames from 'classnames';
import Image from 'next/future/image';
import React, { useRef, useState } from 'react';

import ListLoadingSpinner from '@/cineamo-frontend-lib/components/ui/animation/ListLoadingSpinner';
import BackAndForthButtonOverlay, {
    ScrollState
} from '@/cineamo-frontend-lib/components/ui/buttons/BackAndForthButtonOverlay';
import HorizontalMovieListSkeleton from '@/components/pages/movie/HorizontalMovieListSkeleton';
import LinkWithStyle from '@/components/ui/link/LinkWithStyle';

import { ContentDto } from '@/cineamo-frontend-lib/models/content/ContentDto';

import { loadAsset } from '@/cineamo-frontend-lib/helper/asset-helper';
import {
    getMoviePosterBlurDataImage,
    getMoviePosterImage,
    getMovieTitle
} from '@/cineamo-frontend-lib/helper/movie-helper';
import { StyleFadeDirection, styleFaded } from '@/cineamo-frontend-lib/helper/styleHelper';

export type HorizontalMovieListProps = {
    className?: string;
    contents: ContentDto[];
    onMovieItemClick?: (movie: ContentDto, index: number) => void;
    onMovieItemHover?: (movie: ContentDto, index: number) => void;
    selectedContentItem?: ContentDto;
    paginationLoaderRef?: (node?: Element) => void;
    paginationIsFetching?: boolean;
    paginationHasNextPage?: boolean;
    locale: string;
};

function HorizontalMovieList(props: HorizontalMovieListProps): JSX.Element {
    const {
        className,
        contents,
        selectedContentItem,
        onMovieItemClick,
        onMovieItemHover,
        paginationLoaderRef,
        paginationIsFetching,
        paginationHasNextPage,
        locale
    } = props;

    const movieScrollContainerRef = useRef(null);

    const [scrollState, setScrollState] = useState<ScrollState>({
        startReached: true,
        endReached: false
    });

    function linkToScreenings(content: ContentDto) {
        if (content._embedded?.cineamoMovie) {
            return `/movies/${content._embedded.cineamoMovie.slug}`;
        }
        return `/program/${content.id}`;
    }

    return (
        <div className={classnames(className)}>
            <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-72 space-y-24 lg:space-y-0 items-start">
                <div className="relative w-full">
                    <div
                        className="relative flex flex-row overflow-x-auto space-x-16 no-scrollbar"
                        style={styleFaded(
                            scrollState.startReached
                                ? StyleFadeDirection.Right
                                : scrollState.endReached
                                ? StyleFadeDirection.Left
                                : StyleFadeDirection.Horizontal,
                            5
                        )}
                        ref={movieScrollContainerRef}>
                        {contents?.map((content, index) => {
                            const movie = content._embedded?.cineamoMovie;
                            const movieImage = getMoviePosterImage(movie, locale, true, false);
                            const movieBlurDataImage = getMoviePosterBlurDataImage(movie, locale, true, false);
                            const selectedContentTitle = getMovieTitle(movie, locale) || content.name;
                            const isActiveContentItem = movie && content?.id === selectedContentItem?.id;

                            return (
                                <LinkWithStyle
                                    key={`HorizontalMovieListItem#${content.id}`}
                                    className={classnames('hover:opacity-100 duration-100', {
                                        'opacity-70': selectedContentItem && !isActiveContentItem
                                    })}
                                    href={linkToScreenings(content)}
                                    onMouseEnter={() => onMovieItemHover && onMovieItemHover(content, index)}
                                    onClick={() => onMovieItemClick && onMovieItemClick(content, index)}
                                    ref={
                                        contents.length >= 4 && index === contents.length - 4
                                            ? paginationLoaderRef
                                            : undefined
                                    }
                                    /*
                                     * Prefetch the full page data beyond the loading.js boundaries by setting the prefetch prop to true
                                     * (See: https://nextjs.org/docs/app/building-your-application/routing/linking-and-navigating#2-prefetching)
                                     */
                                    prefetch={true}>
                                    <div className="relative w-[100px] h-[148px] lg:w-[130px] lg:h-[195px]">
                                        <Image
                                            className="object-cover rounded-4 cursor-pointer"
                                            fill
                                            placeholder="blur"
                                            blurDataURL={
                                                movieBlurDataImage
                                                    ? movieBlurDataImage
                                                    : loadAsset(
                                                          '/images/placeholders/portrait/im-posterFilmWithTitlePortrait.jpg'
                                                      )
                                            }
                                            src={
                                                movieImage
                                                    ? movieImage
                                                    : loadAsset(
                                                          '/images/placeholders/portrait/im-posterFilmWithTitlePortrait.jpg'
                                                      )
                                            }
                                            title={selectedContentTitle}
                                            alt={`${selectedContentTitle} poster`}
                                        />
                                        {!movieImage && (
                                            <span className="absolute left-0 bottom-0 p-4 w-full text-body-bold-10 text-center opacity-80">
                                                {selectedContentTitle}
                                            </span>
                                        )}
                                    </div>
                                </LinkWithStyle>
                            );
                        })}

                        {paginationHasNextPage !== false && <HorizontalMovieListSkeleton />}

                        {paginationHasNextPage !== false && (
                            <ListLoadingSpinner
                                className={classnames(
                                    'flex-none pr-24 w-min',
                                    { 'opacity-0': !paginationIsFetching },
                                    { 'w-auto ': contents?.length > 0 }
                                )}
                            />
                        )}
                    </div>
                    {contents?.length > 0 && (
                        <BackAndForthButtonOverlay
                            scrollContainerRef={movieScrollContainerRef}
                            onScrollStateChange={(scrollState) => setScrollState(scrollState)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default HorizontalMovieList;
