import React, { HTMLAttributeAnchorTarget } from 'react';

import LinkWithStyle from '@/components/ui/link/LinkWithStyle';

import { APP_STORE_URL, PLAY_STORE_URL } from '@/src/constants/httpConstants';

import { loadAsset } from '@/cineamo-frontend-lib/helper/asset-helper';

const pathAppStoreBadge = loadAsset('/images/badges/im-appStoreDE.png');
const pathPlayStoreBadge = loadAsset('/images/badges/im-playStoreDE.png');
const pathWebVersionBadge = loadAsset('/images/badges/im-webVersionDE.png');

export enum PlatformBadgeType {
    PlayStore,
    AppStore,
    WebVersion
}

export type PlatformBadgeProps = {
    type: PlatformBadgeType;
    target?: HTMLAttributeAnchorTarget;
};

function PlatformBadge(props: PlatformBadgeProps): JSX.Element {
    let badgeImage, url;
    switch (props.type) {
        case PlatformBadgeType.AppStore:
            badgeImage = pathAppStoreBadge;
            url = APP_STORE_URL;
            break;
        case PlatformBadgeType.PlayStore:
            badgeImage = pathPlayStoreBadge;
            url = PLAY_STORE_URL;
            break;
        default:
            badgeImage = pathWebVersionBadge;
            url = '/program';
            break;
    }

    return url.startsWith('/') ? (
        <LinkWithStyle
            className="cursor-pointer flex-none"
            href={url}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
                className="h-48"
                src={badgeImage}
                alt={props.type.toString()}
            />
        </LinkWithStyle>
    ) : (
        <a
            className="relative flex-none"
            href={url}
            target={props.target || '_blank'}
            rel="noreferrer">
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
                className="h-48"
                src={badgeImage}
                alt={props.type.toString()}
            />
        </a>
    );
}

export default PlatformBadge;
