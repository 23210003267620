import classnames from 'classnames';
import Image from 'next/future/image';

import LinkWithStyle from '@/components/ui/link/LinkWithStyle';

export type CenteredLogosWithTitleLogo = {
    id: string;
    url: string | StaticImageData;
    link?: string;
    logoClass?: string;
};

export type CenteredLogosWithTitleProps = {
    title?: string;
    logos: CenteredLogosWithTitleLogo[];
};

/**
 *
 * @param props
 * @param props.title
 * @param props.logos {string}
 * @constructor
 */
function CenteredLogosWithTitle(props: CenteredLogosWithTitleProps): JSX.Element {
    const { title } = props;
    const logos = props.logos || [];

    return (
        <div className="flex flex-col flex-auto text-center items-center">
            {title && (
                <h2 className="uppercase text-cyan font-montserrat-semibold tracking-wider uppercase leading-8 mb-38">
                    {title}
                </h2>
            )}
            <div className="justify-evenly flex flex-wrap">
                {logos.map((logo, index) => (
                    <div
                        key={`logo#${index}`}
                        className="relative m-24 lg:m-48 flex flex-col justify-center">
                        <LinkWithStyle
                            className="cursor-pointer flex-none"
                            href={logo.link}
                            target="_blank">
                            <Image
                                className={classnames('w-auto h-full object-contain object-center', logo.logoClass, {
                                    'max-h-32 lg:max-h-42': !logo.logoClass
                                })}
                                width={200}
                                height={200}
                                key={logo.id}
                                src={logo.url}
                                alt={logo.id}
                            />
                        </LinkWithStyle>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CenteredLogosWithTitle;
