import { useTranslation } from 'next-i18next';
import React from 'react';

import CenteredLogosWithTitle, { CenteredLogosWithTitleLogo } from '@/components/ui/text/CenteredLogosWithTitle';

import { loadAsset } from '@/cineamo-frontend-lib/helper/asset-helper';

const blickpunktFilmLogo = loadAsset('/images/companies/known-from/im-blickpunktFilm.png');
const brandEinsLogo = loadAsset('/images/companies/known-from/im-brandEins.svg');
const deutschlandfunkNovaLogo = loadAsset('/images/companies/known-from/im-deutschlandfunkNova.svg');
const gruendenAtWuerzburgLogo = loadAsset('/images/companies/known-from/im-gruendenAtWuerzburg.png');
const startupValleyLogo = loadAsset('/images/companies/known-from/im-startupValley.png');
const tvMainFrankenLogo = loadAsset('/images/companies/known-from/im-tvMainFranken.png');

export default function KnownFromLogosCenteredWithTitle(): JSX.Element {
    const { t } = useTranslation('common');

    const partner: CenteredLogosWithTitleLogo[] = [
        { id: 'l1', url: brandEinsLogo, link: 'https://www.brandeins.de/' },
        { id: 'l2', url: deutschlandfunkNovaLogo, link: 'https://www.deutschlandfunknova.de/' },
        { id: 'l3', url: blickpunktFilmLogo, link: 'https://www.mediabiz.de/' },
        { id: 'l4', url: gruendenAtWuerzburgLogo, link: 'https://gruenden.wuerzburg.de/', logoClass: 'w-180' },
        { id: 'l5', url: startupValleyLogo, link: 'https://www.startupvalley.news/', logoClass: 'w-180' },
        { id: 'l6', url: tvMainFrankenLogo, link: 'https://www.tvmainfranken.de/', logoClass: 'w-180' }
    ];

    return (
        <CenteredLogosWithTitle
            title={t('common:known-from')}
            logos={partner}
        />
    );
}
