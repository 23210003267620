export function localeSpecificImage<T = StaticImageData | string>(locale: string, deImage: T, enImage: T) {
    switch (locale) {
        case 'de':
            return deImage;
        case 'en':
            return enImage;
        default:
            return enImage;
    }
}
