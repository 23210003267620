import { useTranslation } from 'next-i18next';
import Image from 'next/future/image';
import React from 'react';

import Button from '@/cineamo-frontend-lib/components/ui/buttons/Button';
import TwoColumnsOnLg from '@/cineamo-frontend-lib/components/ui/layout-components/TwoColumnsOnLg';
import CenterLayout from '@/cineamo-frontend-lib/components/website/CenterLayout';
import MobileOrDefaultRenderer from '@/components/layouts/MobileOrDefaultRenderer';
import PlatformBadge, { PlatformBadgeType } from '@/components/ui/app-store-badge/PlatformBadge';
import LinkWithStyle from '@/components/ui/link/LinkWithStyle';

import { loadAsset } from '@/cineamo-frontend-lib/helper/asset-helper';
import { StyleFadeDirection, styleFaded } from '@/cineamo-frontend-lib/helper/styleHelper';
import { CINEMA_ID_PLACEHOLDER } from '@/src/helper/CinemaHelper';

const smartphoneWithCineamoAppAndPopcornBackground = loadAsset(
    '/images/pictures/im-smartphoneWithCineamoAppAndPopcornBackground.png'
);

function CineamoAppSection() {
    const { t } = useTranslation();

    return (
        <section>
            <CenterLayout>
                <div className="flex flex-col lg:flex-row w-full min-h-330 h-auto lg:min-h-400 rounded-8 overflow-hidden bg-white bg-opacity-10">
                    <MobileOrDefaultRenderer
                        mobileContent={
                            <div className="relative flex flex-col w-full h-260 justify-center p-16">
                                <Image
                                    className="absolute right-0 bottom-0 min-w-full min-h-300 object-cover transform object-left"
                                    src={smartphoneWithCineamoAppAndPopcornBackground}
                                    width={1000}
                                    height={1000}
                                    alt="Cinema"
                                />
                                <div className="absolute left-0 top-0 w-full h-full bg-darkBlue bg-opacity-40" />
                                <div className="relative flex flex-col space-y-16">
                                    <span className="text-center text-headline-18">
                                        {t('landing-page:your-cinema-in-your-hand')}
                                    </span>
                                    <LinkWithStyle
                                        className="self-center"
                                        href={`/${CINEMA_ID_PLACEHOLDER}/events/request`}>
                                        <Button>{t('landing-page:book-cinema-screen')}</Button>
                                    </LinkWithStyle>
                                </div>
                            </div>
                        }
                        defaultContent={
                            <TwoColumnsOnLg
                                left={
                                    <Image
                                        className="w-auto h-full object-left object-cover"
                                        style={styleFaded(StyleFadeDirection.Right, 80)}
                                        src={smartphoneWithCineamoAppAndPopcornBackground}
                                        width={1000}
                                        height={1000}
                                        alt="Cinema"
                                    />
                                }
                                right={
                                    <div className="flex flex-col lg:-ml-48 px-24 lg:px-0 lg:pr-24 py-24 space-y-16 justify-center h-full">
                                        <div className="flex flex-col space-y-12">
                                            <span className="text-headline-20">
                                                {t('landing-page:your-cinema-in-your-hand')}
                                            </span>
                                            <span className="text-body-14">
                                                {t('landing-page:discover-everything-your-cinema-has-to-offer')}
                                            </span>
                                        </div>
                                        {/* TODO: Hide until real data is available? */}
                                        {/*<SocialProof starsClassName="text-cyan" ratingClassName="text-cyan opacity-100"/>*/}
                                        <div className="flex flex-row flex-wrap gap-24">
                                            <PlatformBadge type={PlatformBadgeType.AppStore} />
                                            <PlatformBadge type={PlatformBadgeType.PlayStore} />
                                        </div>
                                    </div>
                                }
                            />
                        }
                    />
                </div>
            </CenterLayout>
        </section>
    );
}

export default CineamoAppSection;
