import React from 'react';

import CenterLayout from '@/cineamo-frontend-lib/components/website/CenterLayout';
import ChooseCinemaSection from '@/components/pages/landingpage/ChooseCinemaSection';
import CineamoAppSection from '@/components/pages/landingpage/CineamoAppSection';
import MoviesInCinemaSlider from '@/components/pages/landingpage/MoviesInCinemaSlider';
import RentYourCinemaCard from '@/components/pages/landingpage/RentYourCinemaCard';
import KnownFromLogosCenteredWithTitle from '@/components/pages/websites/KnownFromLogosCenteredWithTitle';
import PartnerLogosCenteredWithTitle from '@/components/pages/websites/PartnerLogosCenteredWithTitle';
import CineamoSeo from '@/components/seo/CineamoSeo';

import { loadAsset } from '@/cineamo-frontend-lib/helper/asset-helper';
import { useCampaign } from '@/src/helper/campaign/useCampaign';
import { localeSpecificImage } from '@/src/helper/imageHelper';

import useSeo from '@/src/hooks/useSeo';

import { parallel } from '@/cineamo-frontend-lib/middleware/helpers';
import moviesMiddleware, { MoviesMiddlewareProps } from '@/cineamo-frontend-lib/middleware/moviesMiddleware';
import cinemasMiddleware, { CinemasMiddlewareProps } from '@/src/middleware/cinemasMiddleware';
import localeMiddleware, { LocaleMiddlewareProps } from '@/src/middleware/localeMiddleware';
import withMiddlewareDefault from '@/src/middleware/withMiddlewareDefault';

const cineamoIosAppImageDe = loadAsset('/images/header/im-cineamoIosApp-de.png');
const cineamoIosAppImageEn = loadAsset('/images/header/im-cineamoIosApp-en.png');

export type LandingPageProps = LocaleMiddlewareProps & MoviesMiddlewareProps & CinemasMiddlewareProps;

export default function LandingPage(props: LandingPageProps): JSX.Element {
    const { locale, cinemasProp } = props;

    const seo = useSeo();

    const { welcomeModal } = useCampaign({
        showWelcomeMessage: true
    });

    return (
        <main>
            <CineamoSeo
                title="" /* This is empty on purpose - to have just `Cineamo` as title on homepage */
                description={seo('description.landing-page')}
                image={localeSpecificImage(locale, cineamoIosAppImageDe, cineamoIosAppImageEn)}
                imageAlt={'Cineamo program image'}
                locale={locale}
            />

            {welcomeModal}

            <div className="space-y-72">
                <section className="">
                    <MoviesInCinemaSlider />
                </section>

                <section>
                    <CenterLayout>
                        <RentYourCinemaCard />
                    </CenterLayout>
                </section>

                <ChooseCinemaSection
                    cinemasProp={cinemasProp}
                    locale={locale}
                />

                <CineamoAppSection />

                <section>
                    <CenterLayout>
                        <PartnerLogosCenteredWithTitle />
                    </CenterLayout>
                </section>

                <section>
                    <CenterLayout>
                        <KnownFromLogosCenteredWithTitle />
                    </CenterLayout>
                </section>
            </div>
        </main>
    );
}

export const getServerSideProps = withMiddlewareDefault(
    parallel(
        moviesMiddleware,
        cinemasMiddleware,
        localeMiddleware(['seo', 'landing-page', 'cinema', 'events', 'movie', 'slider', 'genres'])
    )
);
