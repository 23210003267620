import React from 'react';

import HorizontalMovieListItemSkeleton from '@/components/pages/movie/HorizontalMovieListItemSkeleton';

type HorizontalMovieListSkeletonProps = {
    items?: number;
};

export default function HorizontalMovieListSkeleton(props: HorizontalMovieListSkeletonProps): JSX.Element {
    return (
        <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-72 space-y-24 lg:space-y-0 items-start">
            <div className="relative w-full">
                <div className="relative flex flex-row overflow-x-auto space-x-24 no-scrollbar">
                    {[...Array(props.items ?? 3)].map((_, index) => (
                        <HorizontalMovieListItemSkeleton key={`HorizontalMovieListItemSkeleton${index}`} />
                    ))}
                </div>
            </div>
        </div>
    );
}
