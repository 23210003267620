import { Tab } from '@headlessui/react';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useLayoutEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useInterval } from 'react-use';

import Button, { ButtonStyle } from '@/cineamo-frontend-lib/components/ui/buttons/Button';
import DotSeparatedLabel from '@/cineamo-frontend-lib/components/ui/label/DotSeparatedLabel';
import TwoColumnsOnLg from '@/cineamo-frontend-lib/components/ui/layout-components/TwoColumnsOnLg';
import ListPlaceholderSimple from '@/cineamo-frontend-lib/components/ui/list/ListPlaceholderSimple';
import CenterLayout from '@/cineamo-frontend-lib/components/website/CenterLayout';
import MobileOrDefaultRenderer from '@/components/layouts/MobileOrDefaultRenderer';
import TabListLine from '@/components/misc/TabListLine';
import SliderImages from '@/components/pages/landingpage/SliderImages';
import HorizontalMovieList from '@/components/pages/movie/HorizontalMovieList';
import LinkWithStyle from '@/components/ui/link/LinkWithStyle';

import { ContentDto } from '@/cineamo-frontend-lib/models/content/ContentDto';

import { ContentListResponse } from '@/cineamo-frontend-lib/api/ApiListResponse.types';
import { getContents } from '@/cineamo-frontend-lib/api/content/content-api';

import { getMovieOverview, getMovieReleaseDate, getMovieTitle } from '@/cineamo-frontend-lib/helper/movie-helper';
import { StyleFadeDirection, styleFaded } from '@/cineamo-frontend-lib/helper/styleHelper';
import { useIsMobile } from '@/src/helper/device/screen-type';

import useHorizontalSwipeRecognizer from '@/cineamo-frontend-lib/hooks/useHorizontalSwipeRecognizer';
import useLocale from '@/cineamo-frontend-lib/hooks/useLocale';
import { usePagination } from '@/cineamo-frontend-lib/hooks/usePagination';

import { IconRightArrow16 } from '@/cineamo-frontend-lib/icons/arrows/ic-rightArrow';

// type MovieInCinemaSliderType = {
//     moviesProp: ApiResponse<MovieListResponse>
// };

function MoviesInCinemaSlider(/*props: MovieInCinemaSliderType*/) {
    const [isInitialized, setIsInitialized] = useState(false);
    const [isMouseOverHeaderArea, setIsMouseOverHeaderArea] = useState(false);
    const [selectedContentIndex, setSelectedContentIndex] = useState(0);
    const [showSelectedContent, setShowSelectedContent] = useState(true);

    const [selectedContent, setSelectedContent] = useState<ContentDto>();
    const [selectedContent2, setSelectedContent2] = useState<ContentDto>();

    const { t } = useTranslation();

    const { locale: browserLocale } = useLocale();

    const { locale } = useRouter();
    const isMobile = useIsMobile('md');
    const { ref: headerRef } = useHorizontalSwipeRecognizer({
        onLeftSwipe: selectNextContent,
        onRightSwipe: selectPreviousContent
    });

    const {
        ref,
        data: contentListResponse,
        isFetching: isFetchingContentList,
        // isFetchingNextPage,
        hasNextPage
        // hasPreviousPage,
        // refetch
    } = usePagination<ContentListResponse>(
        ['movies-in-cinemas'],
        async (pageParam) =>
            await getContents({
                isMovie: true,
                isShowingNow: true,
                sort: 'popularity',
                order: 'desc',
                cinemaCountryCode: 'de',
                page: pageParam
            })
        //     {
        //     initialData: {
        //         pages: [
        //             moviesProp
        //         ],
        //         pageParams: [moviesProp?._page]
        //     }
        // }
    );

    const contents: ContentDto[] = contentListResponse?.pages?.flatMap((page) => page?._embedded?.contents ?? []) ?? [];
    const emptyData = !(contentListResponse?.pages[0]?._total_items > 0);

    function linkToScreenings(content: ContentDto) {
        if (content._embedded?.cineamoMovie) {
            return `/movies/${content._embedded.cineamoMovie.slug}`;
        }
        return `/program/${content.id}`;
    }

    useLayoutEffect(() => {
        const firstContent = contents?.[0];
        if (!isInitialized && firstContent) {
            setSelectedContent(firstContent);
            setIsInitialized(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentListResponse]);

    useInterval(() => {
        if (isInitialized && contents?.length > 1 && !isMouseOverHeaderArea) {
            selectNextContent();
        }
    }, 10000);

    function selectMovie(index: number) {
        const newSelectedMovieIndex = index;
        const movie = contents[newSelectedMovieIndex];
        setSelectedContentIndex(newSelectedMovieIndex);
        if (showSelectedContent && movie !== selectedContent) {
            setSelectedContent2(movie);
            setShowSelectedContent(false);
        } else if (movie !== selectedContent2) {
            setSelectedContent(movie);
            setShowSelectedContent(true);
        }
    }

    function selectNextContent() {
        const newSelectedMovieIndex = (selectedContentIndex + 1) % contents.length;
        selectMovie(newSelectedMovieIndex);
    }

    function selectPreviousContent() {
        const newSelectedMovieIndex = (selectedContentIndex - 1) % contents.length;
        selectMovie(newSelectedMovieIndex);
    }

    function handleEventTabsChange(/* index: number */) {
        // TODO: Handle event tabs change
    }

    function selectedMovieView(content: ContentDto, className: string): JSX.Element {
        const movie = content._embedded?.cineamoMovie;
        const movieTitle = getMovieTitle(movie, locale) || content.name;
        const movieReleaseDate = getMovieReleaseDate(movie, browserLocale)?.substring(0, 4);
        const movieDescription = getMovieOverview(movie, locale) || content.description;
        return (
            <div className={className}>
                <div className="text-headline-20 md:text-headline-24">{movieTitle}</div>
                <div className="text-body-bold-12 md:text-body-bold-14 mt-6 opacity-50">
                    <DotSeparatedLabel
                        labels={[
                            movieReleaseDate,
                            ...(movie?.genres?.slice(0, 3)?.map((genre) => t(`genres:tmdb-genre-${genre.id}`)) || [])
                        ]}
                    />
                </div>
                {!isMobile && <div className="text-body-14 mt-24 line-clamp-3 md:line-clamp-5">{movieDescription}</div>}
                <LinkWithStyle
                    className="text-body-bold-12 md:text-body-bold-14 text-cyan clickable-element mt-6"
                    href={linkToScreenings(content)}
                    /*
                     * Prefetch the full page data beyond the loading.js boundaries by setting the prefetch prop to true
                     * (See: https://nextjs.org/docs/app/building-your-application/routing/linking-and-navigating#2-prefetching)
                     */
                    prefetch={true}>
                    <Button
                        labelClassName="text-body-bold-12 lg:text-body-bold-14"
                        style={ButtonStyle.text_only_no_padding}
                        leadingIcon={<IconRightArrow16 />}
                        labelNotUppercase>
                        {t('landing-page:go-to-screenings')}
                    </Button>
                </LinkWithStyle>
            </div>
        );
    }

    return (
        <div
            onMouseOver={() => setIsMouseOverHeaderArea(true)}
            onMouseOut={() => setIsMouseOverHeaderArea(false)}
            onMouseLeave={() => setIsMouseOverHeaderArea(false)}>
            <div
                className="relative flex flex-col w-full min-h-240 md:min-h-500 bg-cover bg-center bg-no-repeat"
                ref={headerRef}>
                <MobileOrDefaultRenderer
                    mobileContent={
                        <div
                            className="absolute top-0 bottom-0 right-0 left-0 -z-10 bg-cover bg-center bg-no-repeat "
                            style={styleFaded(StyleFadeDirection.Bottom, 100)}>
                            <SliderImages
                                showSelectedMovie={showSelectedContent}
                                selectedMovie={selectedContent?._embedded?.cineamoMovie}
                                selectedMovie2={selectedContent2?._embedded?.cineamoMovie}
                                locale={locale}
                            />
                        </div>
                    }
                    defaultContent={
                        <div
                            className="absolute top-0 bottom-0 right-0 left-0 -z-10 bg-cover bg-center bg-no-repeat"
                            style={styleFaded(StyleFadeDirection.Left, 100)}>
                            <SliderImages
                                showSelectedMovie={showSelectedContent}
                                selectedMovie={selectedContent?._embedded?.cineamoMovie}
                                selectedMovie2={selectedContent2?._embedded?.cineamoMovie}
                                locale={locale}
                            />
                        </div>
                    }
                />

                {/* Header Movie Background */}
                <div className={classnames('relative flex flex-col w-full h-240 md:min-h-400 flex-auto pb-16 md:pb-0')}>
                    <div className="flex flex-col flex-auto">
                        <CenterLayout className="flex flex-col flex-auto">
                            <TwoColumnsOnLg
                                className="flex-auto w-full"
                                left={
                                    <div className="flex flex-col flex-auto relative w-full justify-end md:justify-center">
                                        {selectedContent &&
                                            selectedMovieView(
                                                selectedContent,
                                                classnames(
                                                    'absolute left-0 flex flex-col w-full',
                                                    'transition-all duration-500',
                                                    showSelectedContent
                                                        ? 'opacity-100'
                                                        : 'opacity-0 pointer-events-none'
                                                )
                                            )}

                                        {selectedContent2 &&
                                            selectedMovieView(
                                                selectedContent2,
                                                classnames(
                                                    'flex flex-col w-full',
                                                    'transition-all duration-500',

                                                    !showSelectedContent
                                                        ? 'opacity-100'
                                                        : 'opacity-0 pointer-events-none'
                                                )
                                            )}
                                    </div>
                                }
                                right={<></>}
                                leftExtraClass={classnames('flex-auto lg:flex-none items-center lg:w-1/3')}
                                rightExtraClass="lg:!w-2/3"
                            />
                        </CenterLayout>
                    </div>
                </div>

                {/* Tabs */}
                <CenterLayout className="lg:absolute h-auto z-20 bottom-0 left-0 right-0 flex flex-col">
                    <div className="w-full">
                        <Tab.Group onChange={handleEventTabsChange}>
                            <Tab.List className="relative">
                                <Tab as="div">
                                    {({ selected }) => (
                                        <button
                                            className={classnames(
                                                'py-12',
                                                selected ? 'border-b-2' : 'opacity-50',
                                                'text-overline-12 lg:text-overline-16'
                                            )}>
                                            {t('landing-page:in-cinemas')}
                                        </button>
                                    )}
                                </Tab>
                                <TabListLine />
                            </Tab.List>
                        </Tab.Group>
                    </div>
                </CenterLayout>
            </div>
            <div className="flex pt-12 lg:pt-24">
                <CenterLayout className="flex flex-col">
                    {!isFetchingContentList && contentListResponse && emptyData ? (
                        <div className="flex flex-col items-center h-[148px] lg:h-[195px]">
                            <ListPlaceholderSimple text={t('landing-page:currently-no-screenings-in-cinemas')} />
                        </div>
                    ) : (
                        <HorizontalMovieList
                            contents={contents}
                            selectedContentItem={showSelectedContent ? selectedContent : selectedContent2}
                            onMovieItemHover={(_, index) => selectMovie(index)}
                            paginationLoaderRef={ref}
                            paginationIsFetching={isFetchingContentList}
                            paginationHasNextPage={hasNextPage}
                            locale={locale}
                        />
                    )}
                </CenterLayout>
            </div>
        </div>
    );
}

export default MoviesInCinemaSlider;
