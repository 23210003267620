import React from 'react';

import { Campaign } from '@/src/helper/campaign/campaign.types';

import { IconKinoFuehlstDu } from '@/public/icons/ic-kinoFuehlstDu';

export const ACTIVE_CAMPAIGNS: Campaign[] = [
    {
        name: 'kino-fuehlst-du',
        startDatetime: '2024-06-10T22:00:00Z',
        endDatetime: '2024-12-31T22:00:00Z',
        colors: {
            backgroundColor: '#000000',
            onBackgroundColor: '#FFF184',
            primaryColor: '#FFF184',
            onPrimaryColor: '#000000'
        },
        translations: {
            de: {
                welcomeTitle: 'Herzlich willkommen auf Cineamo!',
                welcomeDescription:
                    'Tauche ein in die Welt der neuesten Filme und bleib stets mit deinem Lieblingskino verbunden.\n\nWähle jetzt deine Lieblingskinos aus und entdecke, was sie zu bieten haben.',
                // eslint-disable-next-line
                welcomeButton: 'Los geht\'s!'
            },
            en: {
                welcomeTitle: 'Welcome to Cineamo!',
                welcomeDescription:
                    'Dive into the world of the latest movies and stay connected with your favorite cinema.\n\nSelect your favorite cinemas now and discover what they have to offer.',
                // eslint-disable-next-line
                welcomeButton: 'Let\'s go!'
            }
        },
        assets: {
            welcomeIcon: <IconKinoFuehlstDu className="w-[56px] h-[56px] flex-none" />
        }
    }
];
