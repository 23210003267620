import classnames from 'classnames';
import React, { ReactElement } from 'react';

function TwoColumnsOnLg(props: {
    left;
    right;
    className?: string;
    leftExtraClass?: string;
    rightExtraClass?: string;
}): ReactElement {
    return (
        <div className={classnames(props.className, 'flex flex-col lg:flex-row')}>
            <div
                className={classnames(props.leftExtraClass, 'flex bg flex-col self-center', {
                    'w-full lg:w-1/2': props.right
                })}>
                {props.left}
            </div>

            {props.right && (
                <div
                    className={classnames(props.rightExtraClass, 'flex flex-col self-center', {
                        'w-full lg:w-1/2': props.left
                    })}>
                    {props.right}
                </div>
            )}
        </div>
    );
}

export default TwoColumnsOnLg;
