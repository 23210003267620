import { Campaign } from '@/src/helper/campaign/campaign.types';
import { ACTIVE_CAMPAIGNS } from '@/src/helper/campaign/campaigns';

export function getCampaignForQueryValue(queryValue: string): Campaign | undefined {
    return ACTIVE_CAMPAIGNS.find(
        (campaign) =>
            campaign.name === queryValue &&
            new Date() >= new Date(campaign.startDatetime) &&
            new Date() <= new Date(campaign.endDatetime)
    );
}
